.writer-container {
  margin: 24px 8px 8px 8px;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

input {
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 4px;
  margin: 4px;
  font-size: 16px;
  width: 100%;
  height: 20px;
}
